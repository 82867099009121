import { BillingReminderSnack } from '@dt/billing-info';
import { OnboardingBanner } from '@dt/components';
import '@dt/global';
import {
  MenuApi,
  MenuCloud,
  MenuDashboard,
  MenuDevSecOps,
  MenuManagement,
  MenuMobile,
  MenuScs,
  MenuSupplyChainSecurity,
  MenuWeb,
} from '@dt/material-components/navigation/ConfigurableLHSMenuConfiguration';
import { Stack, styled } from '@mui/material';
import { Notifier } from '@dt/notifications';
import { useLocation } from '@reach/router';
import useGlobalStore from '@store/global';
import { useSelector } from 'react-redux';
import 'reactflow/dist/style.css';
import ApplicationRouting from './ApplicationRouting';
import ConfigurableLHSMenu from './components/ConfigurableLHSMenu';
import DTPageTitle from './components/DTPageTitle';
import V2Banner from './components/V2Banner';
import Navigation from './components/navigation/Navigation';
import ScrollToTopOnNavigation from './components/navigation/ScrollToTopOnNavigation';

const Container = styled('div')({
  backgroundColor: 'rgb(244, 245, 252)',
  flexGrow: 1,
  minHeight: '100vh',
  overflow: 'hidden',
  width: '100%',
});

// prettier-ignore
const getRoutesSidebarContent = data => {
    return [
      ['/api/v2', null],
      ['/mobile', null],
      ['/login', null],
      ['/management/v2', null],
      ['/devsecops/v2', null],
      ['/cloud/v2', null],
      ['/policies/v2', null],
      ['/openscan/v2', null],
      ['/web/v2', null],
      ['/dashboard', null],
      ['/management/share', null],
      ['/management/onboarding', null],
      ['/api', MenuApi],
      ['/cloud', MenuCloud],
      ['/devsecops', MenuDevSecOps],
      ['/management', MenuManagement],
      ['/mobile-secure', MenuMobile({ isMobileSecure: true })],
      ['/mobile', MenuMobile({ isMobileSecure: false })],
      ['/openscan', MenuSupplyChainSecurity({ accountInfo: data?.account_info, currentUser: data?.current_user })],
      ['/policies', MenuDashboard],
      ['/supply-chain', MenuScs({ accountInfo: data?.account_info, currentUser: data?.current_user })],
      ['/supply-chain/supplier-trust', MenuScs({ accountInfo: data?.account_info, currentUser: data?.current_user })],
      ['/web', MenuWeb],
    ];
  };

const getRoutesBannerContent = onboarding_completed => {
  return [
    ['/api', <OnboardingBanner fullScreen key="api" onboarding_completed={onboarding_completed} />],
    ['/cloud', <OnboardingBanner fullScreen key="cloud" onboarding_completed={onboarding_completed} />],
    ['/dashboard', <OnboardingBanner fullScreen key="cloud" onboarding_completed={onboarding_completed} />],
    ['/devsecops', null],
    ['/management', null],
    ['/mobile-secure', <OnboardingBanner fullScreen key="mobile-secure" onboarding_completed={onboarding_completed} />],
    ['/mobile', <OnboardingBanner fullScreen key="mobile" onboarding_completed={onboarding_completed} />],
    ['/openscan', <OnboardingBanner fullScreen key="openscan" onboarding_completed={onboarding_completed} />],
    ['/policies', null],
    ['/supply-chain', <OnboardingBanner fullScreen key="supply-chain" onboarding_completed={onboarding_completed} />],
    ['/web', <OnboardingBanner fullScreen key="web" onboarding_completed={onboarding_completed} />],
  ];
};

const findMatchingConfig = (content, path) => {
  return content.find(config => {
    const regexp = new RegExp(`${config[0]}/`, 'gi');
    const isMatch = path.match(regexp);
    if (!isMatch) {
      const regexpNoTrailing = new RegExp(`${config[0]}`, 'gi');
      const isMatchNoTrailing = path.match(regexpNoTrailing);
      return isMatchNoTrailing;
    }
    return isMatch;
  });
};

const DTApplication = () => {
  const { userSession, loadingSession } = useGlobalStore();
  const location = useLocation();
  const pathname = location.pathname;
  const isSharedPath = location.pathname.indexOf('/share') !== -1;
  const notifications = useSelector(({ notifications }) => notifications);

  const onboarding_completed = userSession?.account_info.onboarding_completed;

  const routesSidebarContent = getRoutesSidebarContent(userSession);
  const routesBannerContent = getRoutesBannerContent(onboarding_completed);

  const menu = findMatchingConfig(routesSidebarContent, pathname);
  const banner = findMatchingConfig(routesBannerContent, pathname);

  return (
    <ScrollToTopOnNavigation>
      <DTPageTitle>
        {userSession && <V2Banner />}

        <Stack direction="row">
          {userSession && (
            <ConfigurableLHSMenu
              configuration={menu?.[1]}
              data={userSession}
              loading={loadingSession}
              path={`${menu?.[0]}/*`}
            />
          )}

          <Container data-testid="pageContent">
            {userSession && !isSharedPath && <Navigation />}

            {userSession && banner?.[1] && banner[1]}

            <ApplicationRouting />

            {userSession && !isSharedPath && <BillingReminderSnack />}

            {userSession && <Notifier notifications={notifications} />}
          </Container>
        </Stack>
      </DTPageTitle>
    </ScrollToTopOnNavigation>
  );
};

export default DTApplication;
